.container {
  padding: 24px 8px;
  height: 348px;
  width: 192px;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--palette-common-grey-150);
  // margin-bottom: 16px;
  background-color: var(--palette-common-neutral-white);
  border-radius: 4px;
  align-items: center;
  text-align: center;
}

.image {
  width: 132px;
  height: 82px;
  border-radius: 8px;
  overflow: hidden;
}

.name {
  margin-top: 30px;
  margin-bottom: 8px;
  text-align: center;
  width: 120px;
  // line-height: 20px;
}

.valueContainer {
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 12px;
}

.dollarIcon {
  margin-right: 4px;
  width: 28px;
  height: 28px;
}

.button {
  margin-top: auto;
  width: 112px;
}

.imageContainer {
  position: relative;
  height: 82px;
}

.imageLabel {
  position: absolute;
  top: 0;
  right: 8px;
  z-index: 20;
  display: flex;
}

.currency {
  font-size: 10px;
  line-height: 20px;
  margin-top: 2px;
  margin-right: 1px;
}

.amount {
  font-size: 12px;
  line-height: 20px;
  margin-top: 5px;
}

.voucherImageLabel {
  position: absolute;
  bottom: 6px;
  left: 8px;
  z-index: 20;
  display: flex;
}

.voucherAmount {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  display: inline-flex;
  align-items: flex-end;
}

.voucherText {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.link {
  cursor: pointer;
}
